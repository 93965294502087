import React, { Component } from 'react';
import { MDBBtn, MDBAlert } from 'mdb-react-ui-kit';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const resetPasswordValidationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  email: Yup.string().email().required('Email is required'),
});

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, { setStatus }) {
    this.props.onResetPasswordClick(values, setStatus);
  }

  render() {
    return (
      <Formik
        initialValues={{
          username: '',
          email: '',
        }}
        onSubmit={this.handleSubmit}
        validationSchema={resetPasswordValidationSchema}
      >
        {({ status, errors, touched, submitCount }) => (
          <Form>
            {status && status.api_error_message && (
              <MDBAlert open color="danger">
                {status.api_error_message}
              </MDBAlert>
            )}
            {status === false && submitCount > 0 && (
              <MDBAlert open color="danger">
                Something went wrong. Please try again.
              </MDBAlert>
            )}
            {status && status.success_message && (
              <MDBAlert open color="success">
                {status.success_message}
              </MDBAlert>
            )}
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="username" style={{ display: 'block' }}>
                Username <sup>*</sup>
              </label>
              <Field type="text" name="username" className="form-control" />
              <ErrorMessage name="username" className="error" component="div" />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="email" style={{ display: 'block' }}>
                Email <sup>*</sup>
              </label>
              <Field type="text" name="email" className="form-control" />
              <ErrorMessage name="email" className="error" component="div" />
            </div>
            {touched.username && Object.keys(errors).length === 0 ? (
              <MDBBtn
                gradient="blue"
                type="submit"
                className="btn-block z-depth-2"
                style={{ marginTop: '20px', marginLeft: '0px' }}
              >
                Reset Password
              </MDBBtn>
            ) : (
              <MDBBtn
                gradient="blue"
                type="submit"
                className="btn-block z-depth-2"
                style={{ marginTop: '20px', marginLeft: '0px' }}
                disabled
              >
                Reset Password
              </MDBBtn>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

ResetPasswordForm.propTypes = {
  onResetPasswordClick: PropTypes.func,
  account: PropTypes.object,
};

export default ResetPasswordForm;
