import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as SihaAPI from '../../../SihaAPI';
import ResetPasswordView from '../ui/ResetPasswordView';

class PatientReset extends Component {
  constructor(props) {
    super(props);
    this.resetPasswordView = this.resetPasswordView.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
  }

  resetPasswordView = () => {
    this.setState(prevState => ({
      ...prevState,
    }));
  };

  resetPassword = () => {
    this.handleResetPasswordClick();
    this.resetPasswordView();
  };

  handleResetPasswordClick(values, setStatus) {
    const updatedPasswordFields = {
      password: values.newPassword,
      token: this.props.match.params.token,
    };

    return SihaAPI.post('patients/reset', updatedPasswordFields)
      .then(json => {
        if ('error' in json) {
          setStatus({ api_error_message: json.error });
        }
        if (Object.keys(json).length === 0) {
          setStatus({
            success_message: 'Successfully updated password.',
          });
        }
      })
      .catch(error => {
        console.error('Error during reset-password:', error);
      });
  }

  render() {
    return (
      <ResetPasswordView
        onResetPasswordClick={this.handleResetPasswordClick}
        onResetPasswordView={this.resetPasswordView}
      />
    );
  }
}

PatientReset.propTypes = {};

export default withRouter(PatientReset);
