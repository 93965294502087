import React, { Component, Suspense, lazy } from "react";

import * as SihaAPI from "./SihaAPI";
import { Route, Switch, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import ErrorHandler from "./shared/ErrorHandler";
import Navbar from "./shared/Navbar";
import Login from "./views/Login/containers/Login";
import PatientReset from "./views/PatientReset/containers/PatientReset";

class App extends Component {
	constructor(props) {
		super(props);
		this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
		this.handleNavBarDropdownClick =
			this.handleNavBarDropdownClick.bind(this);
		this.handleLogoutClick = this.handleLogoutClick.bind(this);
		this.handleUpdatePasswordClick =
			this.handleUpdatePasswordClick.bind(this);
		this.handleClinicSelectChange =
			this.handleClinicSelectChange.bind(this);
		this.state = {
			account: { username: "", password: "" },
			clinicianDetails: { name: "", email: "" },
			clinicNames: [],
			selectedClinic: {},
			clinicDetails: {},
			loggedIn: false,
			loading: true,
			topNavDropdownCollapse: false,
			displayUpdatePasswordView: false,
			dataTypesExist: false,
		};
	}

	componentDidMount() {
		this.myController = new AbortController();
		this.mySignal = this.myController.signal;
		this.getAllClinics();

		this.checkLoggedInStatus();

		Object.keys(this.state.selectedClinic).length > 0 &&
			this.getClinicDetails(Cookies.get("clinic"));
	}

	checkLoggedInStatus() {
		if (
			Cookies.get("clinician-token") &&
			Cookies.get("clinic") &&
			Cookies.get("username")
		) {
			this.setState(
				{
					loggedIn: true,
					loading: false,
					selectedClinic: { value: Cookies.get("clinic") },
					account: { username: Cookies.get("username") },
				},
				() => {
					this.getClinicDetails(Cookies.get("clinic"));
				}
			);
		} else {
			this.setState({
				loading: false,
				loggedIn: false,
			});
		}
	}

	getAllClinics() {
		SihaAPI.post(
			"clinics/names",
			{
				organization: 1,
			},
			false, // no auth-header
			true
		).then((json) => {
			const allClinics = json.map((clinic) => ({
				value: clinic.id,
				label: clinic.name,
			}));

			this.setState((prevState) => ({
				...prevState,
				clinicNames: allClinics,
			}));
		});
	}

	handleClinicSelectChange(selectedClinic) {
		this.setState(
			{
				selectedClinic,
			},
			() => {
				Cookies.set("clinic", selectedClinic.value);
			}
		);
	}

	getClinicDetails = (id) => {
		SihaAPI.get(`clinics/id/${id}`)
			.then((clinicData) => {
				this.setState(
					(prevState) => ({
						...prevState,
						clinicDetails: {
							...prevState.clinic,
							id: clinicData.id,
							name: clinicData.name,
							clinic_type: clinicData.clinic_type,
							created_at: clinicData.created_at,
							data_types: clinicData.data_types,
							formatted_data_types: this.formatDataTypes(
								clinicData.data_types
							),
						},
					}),
					() => this.filterDataTypesForClinic()
				);
			})
			.catch((error) => console.error(error));
	};

	formatDataTypes(data_types) {
		const data_type_options = [
			{
				label: "activity",
				value: "steps, distance, calories, active_sedentary, active_light, active_moderate, active_intense, heart_rate",
			},
			{ label: "body", value: "bmi, weight, fat" },
			{ label: "ECG", value: "ecg" },
			{ label: "sleep", value: "sleep" },
			{ label: "glucose", value: "glucose" },
			{ label: "food", value: "food" },
		];
		const formattedDataTypes = [];
		data_type_options.forEach((d) => {
			d.value
				.split(", ")
				.flat()
				.every((d) => data_types && data_types.includes(d)) &&
				formattedDataTypes.push(d.label);
		});
		return formattedDataTypes;
	}

	filterDataTypesForClinic() {
		const filteredDataTypes = [];
		this.state.clinicDetails.formatted_data_types.indexOf("activity") >
			-1 && filteredDataTypes.push("steps");
		this.state.clinicDetails.formatted_data_types.indexOf("sleep") > -1 &&
			filteredDataTypes.push("sleep");
		this.setState((prevState) => ({
			...prevState,
			clinicDetails: {
				...prevState.clinicDetails,
				filtered_data_types: filteredDataTypes,
			},
		}));
	}

	handleNavBarDropdownClick() {
		this.setState((prevState) => ({
			...prevState,
			topNavDropdownCollapse: !this.state.topNavDropdownCollapse,
		}));
	}

	handleLogoutClick() {
		this.setState(
			(prevState) => ({
				...prevState,
				loggedIn: false,
				displayUpdatePasswordView: false,
				account: { username: "", password: "" },
			}),
			() => {
				Cookies.remove("clinician-token");
				Cookies.remove("clinic");
				Cookies.remove("username");
			}
		);
	}

	handleUpdatePasswordClick() {
		this.setState(
			(prevState) => ({
				...prevState,
				displayUpdatePasswordView: true,
				loggedIn: false,
				account: { username: "", password: "" },
			}),
			() => {
				Cookies.remove("clinician-token");
				Cookies.remove("clinic");
				Cookies.remove("username");
			}
		);
	}

	handleLoginSubmit(values, setStatus) {
		return this.setState(
			(prevState) => ({
				...prevState,
				displayUpdatePasswordView: false,
				account: {
					...prevState.account,
					username: values.username,
					password: values.password,
				},
			}),
			() => {
				SihaAPI.get_basic_auth(
					"clinicians/login",
					`${this.state.selectedClinic.value}/${this.state.account.username}`,
					this.state.account.password
				)
					.then((json) => {
						if ("token" in json) {
							this.storeToken(json.token);

							SihaAPI.get(
								`clinics/id/${this.state.selectedClinic.value}`
							).then((json) => {
								if (
									json.data_types === null ||
									json.data_types.length === 0
								) {
									setStatus({
										api_error_message:
											"No device types in clinic. Please add device type in ",
									});
									Cookies.remove("clinician-token");
								} else {
									this.setState(
										{
											loggedIn: true,
											dataTypesExist: true,
										},
										() => {
											Cookies.set(
												"username",
												this.state.account.username
											);
											this.getClinicDetails(
												this.state.selectedClinic.value
											);
										}
									);
								}
							});
						} else if ("error" in json) {
							setStatus({ api_error_message: json.message });
						}
					})
					.catch((error) => {
						console.error("Error during login:", error);
					});
			}
		);
	}

	storeToken(token) {
		const inHalfADay = 0.5;
		Cookies.set("clinician-token", token, { expires: inHalfADay });
	}

	getAccountDetails() {
		SihaAPI.get(`clinicians/username/${this.state.account.username}`).then(
			(clinicianDetails) => {
				this.setState((prevState) => ({
					...prevState,
					clinicianDetails: {
						...prevState.clinicianDetails,
						name: clinicianDetails.name,
						email: clinicianDetails.email,
					},
				}));
			}
		);
	}

	render() {
		const PublicHome = lazy(() =>
			import("./views/PublicHome/ui/PublicHome")
		);
		const PatientDashboard = lazy(() =>
			import("./views/PatientDashboard/containers/PatientDashboard")
		);
		const AllPatients = lazy(() =>
			import("./views/AllPatients/containers/AllPatients")
		);
		const AddPatient = lazy(() =>
			import("./views/AddPatient/containers/AddPatient")
		);
		const EditPatient = lazy(() =>
			import("./views/EditPatient/containers/EditPatient")
		);

    const Page404 = lazy(() => import('./views/Page404/ui/Page404'));
		const ClinicOverview = lazy(() => import('./views/ClinicOverview/containers/ClinicOverview'));
    const ComingSoon = lazy(() => import('./views/ComingSoon/ui/ComingSoon'));
    const Account = lazy(() => import('./views/Account/containers/Account'));

    const AllGroups = lazy(() => import('./views/AllGroups/containers/AllGroups'));
    const GroupDashboard = lazy(() => import('./views/GroupDashboard/containers/GroupDashboard'));
    const GroupAdministration = lazy(
      () => import('./views/GroupAdministration/containers/GroupAdministration'),
    );
    const RegistrationWizard = lazy(
      () => import('./views/RegistrationWizard/containers/RegistrationWizard'),
    );
    const GroupECG = lazy(
      () => import('./views/GroupECG/containers/GroupECG'),
    );
    return (
      <React.Fragment>
        <Navbar
          isLoggedIn={this.state.loggedIn}
          username={this.state.account.username}
          topNavDropdownCollapse={this.state.topNavDropdownCollapse}
          onNavBarDropdownClick={this.handleNavBarDropdownClick}
          onLogoutClick={this.handleLogoutClick}
        />{' '}
        <ErrorHandler isLoggedIn={this.state.loggedIn}>
          <Suspense fallback={<div>Loading...</div>}>
            {
            this.state.loading ? (<div>Loading...</div>) :
            (<Switch>
              <Route
                path="/login"
                render={() =>
                  this.state.loggedIn &&
                  this.state.selectedClinic &&
                  this.state.account.username ? (
                    <Redirect to="/" />
                  ) : (
                    <Login
                      isLoggedIn={this.state.loggedIn}
                      account={this.state.account}
                      onLogin={this.handleLoginSubmit}
                      clinicNames={this.state.clinicNames}
                      onClinicSelectChange={this.handleClinicSelectChange}
                      selectedClinic={this.state.selectedClinic}
                      displayUpdatePasswordView={this.state.displayUpdatePasswordView}
                      dataTypesExist={this.state.dataTypesExist}
                    />
                  )
                }
              />
              <Route path="/reset/:token" render={() => <PatientReset />} />
              {this.state.loggedIn ? (
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  render={() => <Redirect to="/overview" />}
                />
              ) : (
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={PublicHome} />
              )}
              <Route
                exact
                path="/coming-soon"
                render={() => (this.state.loggedIn ? <ComingSoon /> : <Redirect to="/login" />)}
              />
              <Route
                exact
                path="/overview"
                render={() =>
                  this.state.loggedIn && this.state.selectedClinic ? (
                    <ClinicOverview id={this.state.selectedClinic.value} />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
								/>
              <Route
                exact
                path="/my-account"
                render={() =>
                  this.state.loggedIn &&
                  this.state.selectedClinic &&
                  this.state.account.username ? (
                    <Account
                      username={this.state.account.username}
                      onLogoutClick={this.handleLogoutClick}
                      onUpdatePasswordClick={this.handleUpdatePasswordClick}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/patients"
                render={() =>
                  this.state.loggedIn ? <Redirect to="/patients/all" /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/patients/all"
                render={() =>
                  this.state.loggedIn &&
                  this.state.selectedClinic &&
                  this.state.account.username ? (
                    <AllPatients
                      clinicianName={this.state.account.username}
                      clinic={this.state.selectedClinic}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/patients/add"
                render={() =>
                  this.state.selectedClinic &&
                  this.state.loggedIn &&
                  this.state.account.username ? (
                    <AddPatient
                      clinicianName={this.state.account.username}
                      clinic={this.state.selectedClinic}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/patients/:id/edit"
                component={
                  this.state.loggedIn && this.state.selectedClinic && this.state.account.username
                    ? EditPatient
                    : PublicHome
                }
              />
              <Route
                exact
                path="/patients/:id"
                render={() =>
                  this.state.loggedIn &&
                  this.state.selectedClinic &&
                  this.state.account.username ? (
                    <PatientDashboard
                      clinician={this.state.clinicianDetails}
                      clinic={this.state.clinicDetails}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/groups"
                render={() =>
                  this.state.loggedIn ? <Redirect to="/groups/all" /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/groups/add"
                render={() =>
                  this.state.loggedIn &&
                  this.state.selectedClinic &&
                  this.state.account.username ? (
                    <GroupAdministration
                      username={this.state.account.username}
                      clinic={this.state.selectedClinic}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                key="group"
                path="/groups/all"
                render={() =>
                  this.state.loggedIn &&
                  this.state.selectedClinic &&
                  this.state.account.username ? (
                    <AllGroups
                      clinicianName={this.state.account.username}
                      clinic={this.state.selectedClinic}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/groups/:id"
                render={() =>
                  this.state.loggedIn &&
                  this.state.selectedClinic &&
                  this.state.account.username ? (
                    <GroupDashboard clinicianName={this.state.account.username} />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                    exact
                    path="/groupecg"
                    render={() =>
                      this.state.loggedIn &&
                        this.state.selectedClinic &&
                        this.state.account.username ? (
                        <GroupECG clinic={this.state.selectedClinic.value} />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
              />
                  <Route
                    exact
                    path="/register"
                    render={() => <RegistrationWizard></RegistrationWizard>}
              />

              <Route render={() => <Page404 isLoggedIn={this.state.loggedIn} />} />
              <Route path="/.well-known/apple-developer-domain-association.txt" />
            </Switch>)
            }
          </Suspense>
        </ErrorHandler>
      </React.Fragment>
    );
  }
}

export default App;
