import React from 'react';
import LoginForm from '../components/LoginForm';
import { MDBContainer, MDBRow, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import PropTypes from 'prop-types';

const LoginView = props => {
  return (
    <React.Fragment>
      <MDBContainer
        className="my-5 padding-t20  d-flex align-items-center justify-content-center"
        style={{ height: '80vh' }}
      >
        <div className="d-flex justify-content-center align-items-center ">
          <MDBRow>
            <MDBCard className="w-auto" style={{ minWidth: '35rem' }}>
              <div className="header pt-3 grey lighten-2">
                <MDBRow className="d-flex justify-content-start">
                  <h3 className="deep-grey-text mt-3 mb-4 pb-1 mx-5">Log in</h3>
                </MDBRow>
              </div>
              <MDBCardBody className="mx-4 mt-4">
                <LoginForm
                  isLoggedIn={props.loggedIn}
                  account={props.account}
                  onLogin={props.onLogin}
                  clinicNames={props.clinicNames}
                  onClinicSelectChange={props.onClinicSelectChange}
                  selectedClinic={props.selectedClinic}
                  dataTypesExist={props.dataTypesExist}
                />
                <div className="float-right">
                  Forgot password?
                  <button type="button" className="link-button" onClick={props.onResetPasswordView}>
                    Reset it Here
                  </button>
                </div>
                <br />
                <div className="float-right">
                  Update password?
                  <button
                    type="button"
                    className="link-button"
                    onClick={props.onUpdatePasswordView}
                  >
                    Update it Here
                  </button>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        </div>
      </MDBContainer>
    </React.Fragment>
  );
};

LoginView.propTypes = {
  isLoggedIn: PropTypes.bool,
  account: PropTypes.object,
  clinicNames: PropTypes.array,
  onSubmit: PropTypes.func,
  onClinicSelectChange: PropTypes.func,
  selectedClinic: PropTypes.object,
  onResetPasswordView: PropTypes.func,
  onUpdatePasswordView: PropTypes.func,
  dataTypesExist: PropTypes.bool,
};

export default LoginView;
